var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CCard",
    [
      _c(
        "CCardHeader",
        [
          _c("CIcon", { attrs: { name: "cil-notes" } }),
          _c(
            "h5",
            { staticClass: "d-inline ml-2" },
            [
              _vm._v(" " + _vm._s(this.$route.meta.label) + " "),
              _vm.isEdit
                ? [
                    _vm._v(" : "),
                    _c(
                      "h3",
                      { staticClass: "d-inline" },
                      [
                        _c("CBadge", { attrs: { color: "secondary" } }, [
                          _vm._v(_vm._s(this.itemName)),
                        ]),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "card-header-actions" },
            [
              _c(
                "CButton",
                { attrs: { color: "dark", to: { name: "Restaurant Types" } } },
                [
                  _c("CIcon", {
                    staticClass: "align-bottom",
                    attrs: { name: "cil-arrow-thick-left" },
                  }),
                  _vm._v(" Back "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "CCardBody",
        [
          _c("alert-section", {
            attrs: {
              successAlertMessage: _vm.successAlertMessage,
              dismissSecs: _vm.dismissSecs,
              dismissSuccessAlert: _vm.dismissSuccessAlert,
              errorAlertMessage: _vm.errorAlertMessage,
              showErrorAlert: _vm.showErrorAlert,
            },
          }),
          _c(
            "CForm",
            [
              _c(
                "CRow",
                [
                  _c(
                    "CCol",
                    { attrs: { lg: "6" } },
                    [
                      _c("CInput", {
                        attrs: {
                          label: "Restaurant Type",
                          lazy: false,
                          value: _vm.$v.form.type.$model,
                          isValid: _vm.checkIfValid("type"),
                          placeholder: "Type Name",
                          invalidFeedback:
                            "This is a required field and must be at least 2 characters",
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.$v.form.type, "$model", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "CCol",
                    { attrs: { lg: "6" } },
                    [
                      _c("CInput", {
                        attrs: {
                          label: "Slug",
                          lazy: false,
                          value: _vm.$v.form.slug.$model,
                          isValid: _vm.checkIfValid("slug"),
                          invalidFeedback: "This is a required field",
                          tabindex: "-1",
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.$v.form.slug, "$model", $event)
                          },
                        },
                        model: {
                          value: _vm.typeSlug,
                          callback: function ($$v) {
                            _vm.typeSlug = $$v
                          },
                          expression: "typeSlug",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "CCol",
                    { attrs: { lg: "6" } },
                    [
                      _c("CInput", {
                        attrs: {
                          label: "FR",
                          lazy: false,
                          value: _vm.$v.form.fr.$model,
                          isValid: _vm.checkIfValid("fr"),
                          placeholder: "Type Name in French",
                          invalidFeedback:
                            "This is a required field and must be at least 2 characters",
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.$v.form.fr, "$model", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "CCol",
                    { attrs: { lg: "6" } },
                    [
                      _c("CInput", {
                        attrs: {
                          label: "Slug FR",
                          lazy: false,
                          value: _vm.$v.form.slug_fr.$model,
                          isValid: _vm.checkIfValid("slug_fr"),
                          invalidFeedback: "This is a required field",
                          tabindex: "-1",
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(
                              _vm.$v.form.slug_fr,
                              "$model",
                              $event
                            )
                          },
                        },
                        model: {
                          value: _vm.typeSlugFr,
                          callback: function ($$v) {
                            _vm.typeSlugFr = $$v
                          },
                          expression: "typeSlugFr",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "CCol",
                    { attrs: { lg: "6" } },
                    [
                      _c("CInput", {
                        attrs: {
                          label: "EN",
                          lazy: false,
                          value: _vm.$v.form.en.$model,
                          isValid: _vm.checkIfValid("en"),
                          placeholder: "Type Name in English",
                          invalidFeedback:
                            "This is a required field and must be at least 2 characters",
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.$v.form.en, "$model", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "CCol",
                    { attrs: { lg: "6" } },
                    [
                      _c("CInput", {
                        attrs: {
                          label: "Slug EN",
                          lazy: false,
                          value: _vm.$v.form.slug_en.$model,
                          isValid: _vm.checkIfValid("slug_en"),
                          invalidFeedback: "This is a required field",
                          tabindex: "-1",
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(
                              _vm.$v.form.slug_en,
                              "$model",
                              $event
                            )
                          },
                        },
                        model: {
                          value: _vm.typeSlugEn,
                          callback: function ($$v) {
                            _vm.typeSlugEn = $$v
                          },
                          expression: "typeSlugEn",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "CCol",
                    { attrs: { lg: "6" } },
                    [
                      _c(
                        "CMedia",
                        {
                          attrs: {
                            "aside-image-props": {
                              src: this.previewTypeImage,
                              shape: "rounded",
                            },
                          },
                        },
                        [
                          _c("p", { staticClass: "mb-2 font-weight-bold" }, [
                            _vm._v("Type Image"),
                          ]),
                          _c("CInput", {
                            staticClass: "mb-0",
                            attrs: {
                              value: _vm.$v.form.type_image.$model,
                              type: "hidden",
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.$v.form.type_image,
                                  "$model",
                                  $event
                                )
                              },
                            },
                          }),
                          _c("CInputFile", {
                            attrs: {
                              isValid: _vm.checkIfValid("type_image"),
                              label: "Type Image",
                              placeholder: "Chose an image...",
                              custom: "",
                            },
                            on: { change: _vm.onFileChanged },
                          }),
                        ],
                        1
                      ),
                      _vm.previewTypeImage
                        ? _c(
                            "CLink",
                            {
                              staticClass: "small mt-2 text-danger",
                              on: {
                                click: () => {
                                  _vm.selectedTypeImage = _vm.previewTypeImage =
                                    null
                                  _vm.form.type_image = ""
                                },
                              },
                            },
                            [
                              _c("CIcon", { attrs: { name: "cil-trash" } }),
                              _vm._v(" Remove Image "),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "CCardFooter",
        [
          _c(
            "CButton",
            {
              attrs: { color: "primary", disabled: _vm.submitted },
              on: { click: _vm.submit },
            },
            [_vm._v(" Submit ")]
          ),
        ],
        1
      ),
      _c("CElementCover", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.submitted,
            expression: "submitted",
          },
        ],
        attrs: { opacity: 0.4 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }